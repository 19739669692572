.required::after {
    content: " *";
    color: red;
}

body {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
}

#mainFooter {
    margin-top: 4em;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

#container {
    min-height: 82vh;
}

#pageDropDown {
    margin-right: 1vw;
}

.openlayers-map {
    float: left;
}

#addGeometricElementContainer {
    float: left;
    left: 0;
    width: 30vw;
    min-height: 82vh;
    display: none;
    background-color: white;
    border-left: 6px solid black;
    max-height: 82vh;
    overflow: auto;
}

.carousel .thumb img {
    object-fit: scale-down;
    max-height: 5vh;
    max-width: auto;
}

.carousel .slide img {
    object-fit: scale-down;
    min-height: 70vh;
    max-height: 70vh;
    max-width: auto;
}

.head404 {
    font-size: 200px;
    color: #a9444a;
    font-weight: bolder;
}

.headline {
    font-size: 90px;
    font-weight: bold;
}

.help-tooltip.tooltip.show {
    opacity: 1 !important;
}

.help-tooltip.tooltip-inner {
    font-size: 14px !important;
    max-width: 25vw !important;
    text-align: left !important;
    background-color: snow !important;
    color: black !important;
    border: 3px solid saddlebrown !important;
}

.help-tooltip.tooltip.bs-tooltip-auto[x-placement^=left] .arrow::before, .help-tooltip.tooltip.bs-tooltip-left .arrow::before {
    content: "";
    border-width: 5px 0px 5px 5px !important;
    border-left-color: saddlebrown !important;
}

.help-tooltip.tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before, .help-tooltip.tooltip.bs-tooltip-right .arrow::before {
    content: "";
    border-width: 5px 5px 5px 0px !important;
    border-right-color: saddlebrown !important;
}

.help-tooltip .tooltip-inner p {
    padding-top: 0px !important;
    padding-bottom: 5px !important;
    margin: 0 !important;
}

.help-tooltip .tooltip-inner>*:last-child {
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.help-tooltip .tooltip-inner ul {
    margin-bottom: 5px;
    padding-left: 15px;
}

.help-tooltip .tooltip-inner h1 {
    font-size: 15px;
    margin: 0;
}

td .img-thumbnail {
    object-fit: scale-down;
    max-height: 10vh;
    max-width: 30vw;
}

.react-bootstrap-table table {
    table-layout: auto !important;
}

.layer-switcher.shown>div {
    max-height: 65vh;
}

.layer-switcher .panel {
    line-height: initial !important;
}

.layer-switcher li {
    margin-top: 0 !important;
}

.layer-switcher li.group>label {
    margin-bottom: 0 !important;
    vertical-align: top;
}

.layer-switcher li label {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

th .order-4:before {
    content: "\25B2" !important;
    font-weight: bolder !important;
}

th .order-4:after {
    content: "\25BC" !important;
    font-weight: bolder !important;
}

th .caret-4-asc:before {
    content: "\25B2" !important;
    font-weight: bolder !important;
}

th .caret-4-asc:after {
    content: "" !important;
    font-weight: bolder !important;
}

th .caret-4-desc:before {
    content: "" !important;
    font-weight: bolder !important;
}

th .caret-4-desc:after {
    content: "\25BC" !important;
    font-weight: bolder !important;
}

.react-bootstrap-table {
    margin-top: 15px !important;
}

.alert+#actionButons {
    margin-top: 15px;
}

#actionButtons+.filtersPanel {
    margin-top: 15px;
}

.filtersPanel {
    background-color: whitesmoke;
    padding: 10px;
    clear: both;
}

.filterPanelTitle {
    font-weight: bold;
    padding-right: 10px;
}

.filterPanelItem {
    align-items: center;
    background: rgba(126, 170, 170, 0.2);
    padding: 5px;
    margin: 3px;
    display: inline-flex;
}

.formattedUrl {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
}